import { api } from '../api'

const UserRepository = {
    update (payload) {
        return api().$put('/user', payload)
    },

    checkEmail (email) {
        return api().$post('/user/check-email', { email })
    }
}

export default UserRepository
